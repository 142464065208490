<template>
  <div class='navbar'>
  <b-navbar class="navbar" toggleable="lg" style="width:100%">
    <b-navbar-brand href="#">
      <router-link to="/">The Hydration Project</router-link>
    </b-navbar-brand>
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto" v-if="!session.loggedIn">
        <b-nav-item>
          <router-link to="/login">Log In</router-link>
        </b-nav-item>
        <b-nav-item>
          <router-link to="/createaccount">Create Account</router-link>
        </b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto" v-else>
          <b-nav-item>
            <router-link to="/addtap">Add a tap</router-link>
          </b-nav-item>
          <b-nav-item>
            <p>{{session.username}}</p>
          </b-nav-item>
          <b-nav-item>
            <a href="#" v-on:click="logOut()">Log Out</a>
          </b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
  </div>
</template>
<script>
import sessionMI from '../mixins/session'

export default {
  name: 'Navbar',
  mixins: [sessionMI]
}
</script>
<style>
.navbar {
  background-color: rgb(145, 199, 229);
}
</style>