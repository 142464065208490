export default {
  data: function() {
    return {
      session: {
        loggedIn: false,
        usernme: ""
      }
    }
  },
  created: function() {
    this.updateSession()
  },
  methods: {
    updateSession() {
      this.$http.get(
        this.$apiUrl + 'getsession'
      ).then((res) => {
        this.session = res.data
      }).catch((error) => {
        console.log(error.message)
      })
    },
    logOut() {
      this.$http.post(
        this.$apiUrl + 'logout'
      ).then((res) => {
        if(res.status == 200) {
          this.session.loggedIn = false
        }
      })
    }
  }
}