<template>
  <div>
    <Navbar></Navbar>
    <div class="form-zone">
      <h2>Log In</h2>
      <b-form @submit="logIn">
        <b-form-group
          label="Username:"
          label-for="username" 
        >
          <b-form-input
            id="username"
            type="text"
            placeholder="Username"
            v-model="form.username"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Password:"
          id="pw-group"
          label-for="password" 
        >
          <b-form-input
            id="password"
            type="password"
            placeholder="Password"
            v-model="form.password"
          ></b-form-input>
        </b-form-group>
        <b-button type="submit" variant="primary">Log In</b-button>
      </b-form>
      <router-link to="/createaccount">Create Account</router-link>
    </div>
  </div>
</template>
<script>
import Navbar from '../components/Navbar.vue'
import form from '../mixins/form.js'
export default {
  components: { Navbar },
  name: 'Login',
  data() {
    return {
      form: {
        username: '',
        password: ''
      }
    }
  },
  methods: {
    logIn(event) {
      this.$http.post(
        this.$apiUrl + 'login',
        this.form
      ).then(this.redirect
      ).catch(function(error) {
        console.log(error)
        if(error.response.status == 403) {
          form.highlightError('pw-group', "The username or password you have entered is invalid!")
        }
      })
    },
    redirect(event) {
      this.$router.push('/')
    }
  }
}
</script>