import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import CreateAccount from '../views/CreateAccount.vue'
import AddTap from '../views/AddTap.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name:'Login',
    component: Login
  },
  {
    path: '/createaccount',
    name: 'Create Account',
    component: CreateAccount
  },
  {
    path: '/addtap',
    name: 'Add a Tap',
    component: AddTap
  }
]

const router = new VueRouter({
  routes: routes
})

export default router
