<template>
  <div class="home" style="height: 100%; display:flex; flex-direction:column">
    <Navbar></Navbar>
    <MainMap></MainMap>
  </div>
</template>

<script>
import MainMap from './components/Map.vue'
import Navbar from './components/Navbar.vue'

export default {
  name: 'Home',
  components: {
    MainMap,
    Navbar
  }
}
</script>
