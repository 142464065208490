<template>
  <div>
    <Navbar></Navbar>
    <div class="form-zone">
      <h2>Create Account</h2>
      <b-form @submit="createAccount">
        <b-form-group
          label="Email address:"
          label-for="email"
          id="email-input"
        >
          <b-form-input
            id="email"
            placeholder="Email"
            v-model="form.email"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Username:"
          label-for="username"
          id="username-input"
        >
          <b-form-input
            id="username"
            placeholder="Username"
            v-model="form.username"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Password:"
          label-for="password" 
          id="password-input"
        >
          <b-form-input
            id="password"
            type="password"
            placeholder="Password"
            v-model="form.password"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Confirm password:"
          label-for="password-conf"
          id="password-conf-input"
        >
          <b-form-input
            id="password-conf"
            placeholder="Confirm Password"
            type=password
            v-model="form.passwordconf"
          ></b-form-input>
        </b-form-group>
        <b-button type="submit" variant="primary">Create Account</b-button>
      </b-form>
      <router-link to="/login">Already have an account? Log in.</router-link>
    </div>
  </div>
</template>
<script>
import Navbar from '../components/Navbar.vue'
import form from '../mixins/form'
export default {
  data() {
    return {
      form: {
        email: '',
        username: '',
        password: '',
        passwordconf: ''
      }
    }
  },
  components: { Navbar },
  name: 'CreateAccount',
  methods: {
    createAccount(event) {
      this.$http.post(
        this.$apiUrl + 'createaccount',
        this.form).then(function(response) {
        }).catch(function(error) {
          var res = error.response
          var errors = res.data.errors[0]
          // Input errors
          if(errors.includes('InvalidEmail')) {
            form.highlightError("email-input", "Enter a valid email address.")
          }

          if(errors.includes('InvalidUsername')) {
            form.highlightError("username-input", "Enter a valid username.")
          }

          if(errors.includes('DuplicateUsername')) {
            form.highlightError("username-input", "Username already taken!")
          }

          if(errors.includes('InvalidPassword')) {
            form.highlightError("password-input", "Password must be at least 1 character.")
          }

          if(errors.includes('PasswordMismatch')) {
            form.highlightError("password-conf-input", "Passwords do not match.")
          }
        })
    }
  }
}
</script>
<style>
.errorField input {
  background-color: rgba(236, 183, 183, 0.212);
  border: 1px solid indianred;
}
.errorMessage {
  color: indianred;
}
</style>