<template>
  <div>
    <Navbar></Navbar>
    <div class="form-zone">
      <h2>Add a Tap</h2>
      <b-form @submit="addTap">
        <b-form-group
          label="Click Location on Map"
        >
          <l-map
            style="height: 300px;"
            ref="map"
            :zoom="mapView.zoom"
            :center="mapView.center"
          >
            <l-tile-layer :url="mapView.url"></l-tile-layer>
          </l-map>
          <b-form-input
            id="location-input"
            ref="location"
            readonly
          >
          </b-form-input>
        </b-form-group>
        <b-form-group
          label="Title"
          id="name-input"
        >
          <b-form-input
            class="input-lg"
            id="title"
            v-model="form.tapName"
            placeholder="Title">
          </b-form-input>
        </b-form-group>
        <b-form-group
          label="Description"
          id="description-input">
          <b-form-textarea
            id="description"
            placeholder="Description"
            v-model="form.description">
          </b-form-textarea>
        </b-form-group>
        <b-form-group
          label="Type"
          id="type-input">
          <b-form-select
            :options="tapSchema.type.enum"
            v-model="form.type">
          </b-form-select>
        </b-form-group>
        <b-button type="submit" variant="primary">Add to Map</b-button>
      </b-form>
    </div>
  </div>
</template>
<script>
import Navbar from '../components/Navbar.vue'
import {LMap, LTileLayer} from 'vue2-leaflet';
import { latLng } from "leaflet";

export default {
  components: {
    Navbar,
    LMap,
    LTileLayer
  },
  data: function() {
    return {
      tapSchema: {
        type: {
          enum: []
        }
      },
      mapView: {
        zoom: 13,
        showMap: true,
        center: latLng(40.02, -105.26),
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      },
      marker: null,
      location: 'fake',
      form: {}
    }
  },
  methods: {
    getSchema: function() {
      let self = this
      this.$http.get(this.$apiUrl + 'gettapschema').then(function(res) {
        self.tapSchema = res.data
      })
    },
    addTap: function() {
      // collect data to send to server
      var tapData = {
        name: this.form.tapName,
        lat: this.location.lat,
        lng: this.location.lng,
        description: this.form.description,
        type: this.form.type
      }

      this.$http.post(
        this.$apiUrl + 'addtap',
        tapData
      ).then(
        this.$router.push('/')
      )
    }
  },
  mounted: function() {
    this.$nextTick(function() {
      var map = this.$refs.map.mapObject
      var location = this.$refs.location
      map.on('click', (event) => {
        if(this.marker) {
          map.removeLayer(this.marker)
        }
        this.marker = L.marker(event.latlng).addTo(map)

        // keep track of location to use when submitted
        this.location = {
          lat: event.latlng.lat,
          lng: event.latlng.lng
        }
        location.value = `${event.latlng.lat.toFixed(6)}, ${event.latlng.lng.toFixed(6)}`
      })
    })

    this.getSchema()
  }
}
</script>
