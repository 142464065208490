import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import router from './router'
import BootstrapVue from 'bootstrap-vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import './assets/styles/main.css'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false

// Configuration
Vue.prototype.$apiUrl = 'https://api.hydrationproject.org/' // should be environment variable

Vue.use(BootstrapVue)
Vue.use(VueRouter)

axios.defaults.withCredentials = true
Vue.use(VueAxios, axios)

new Vue({
  router
}).$mount('#app')
