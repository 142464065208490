<template>
  <div id="map-view" style="flex:1 1 auto">
    <l-map
      style="height: 100%; width: 100%;"

      ref="map"
      :center="center"
      :zoom="zoom"
    >
      <l-tile-layer :url="url" :attribution="attribution" />
    </l-map>
  </div>
</template>
<script>
// import L from 'leaflet';
import {LMap, LTileLayer} from 'vue2-leaflet';
import { latLng } from "leaflet";

export default {
  name: 'MainMap',
  components: {
    LMap,
    LTileLayer
  },
  data: function() {
    return {
      zoom: 13,
      showMap: true,
      center: latLng(40.02, -105.26),
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',

    }
  },
  methods: {
    updateMap: function() {
      // Get tap objects
      var map = this.$refs.map.mapObject 
      var bounds = map.getBounds()
      this.$http.post(this.$apiUrl + 'gettaps', {
        bbox: {
          lat: {
            min: bounds._southWest.lat-.5,
            max: bounds._northEast.lat+.5
          },
          lng: {
            min: bounds._southWest.lng-.5,
            max: bounds._northEast.lng+.5
          }
        }
      }).then(function(res) {
        // Show taps on map
        res.data.forEach(tap => {
          var marker = L.marker([tap.lat, tap.lng]).addTo(map)
          marker.bindPopup(`<b>${tap.name}</b>`)
        })
      }).catch(function(err) {
        console.log(err)
      })
    }
  },
  mounted: function() {
    this.updateMap()
  }
}
</script>
<style scoped>
  #map {
    height: 100%;
  }
</style>
