function highlightError(divId, message) {
  var div = document.getElementById(divId)
  var existingErrors = div.getElementsByClassName("errorMessage")
  for(var i = 0; i < existingErrors.length; i++) {
    existingErrors[i].remove()
  }
  div.className = "errorField"
  var messageEl = document.createElement("small")
  messageEl.innerHTML = message
  messageEl.className = "errorMessage"
  div.appendChild(messageEl)
}

module.exports = {
  highlightError
}