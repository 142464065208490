<template>
  <div class="home" style="height: 100%; display:flex; flex-flow:column">
    <Navbar></Navbar>
    <MainMap></MainMap>
  </div>
</template>

<script>
import Navbar from '../components/Navbar.vue'
import MainMap from '../components/Map.vue'
export default {
  name: 'Home',
  components: {
    Navbar,
    MainMap
  }
}
</script>
